<template>
    <div>
        <v-card>            
            <v-container>
                <v-row>
                    <h2 class="mt-3 ml-6">{{ DescriptionTunnel }}</h2>
                    <v-spacer> </v-spacer>
					<v-btn
						@click="$emit('close')"
						class="mt-3 mr-3"
						small
						fab
						color="error"
						><i style="font-size: 16px" class="fas fa-times"></i
					></v-btn>
                </v-row>
                <v-row>
					<v-col class="pb-0">
						<v-divider></v-divider>
					</v-col>
				</v-row>
                <v-row>
                    <v-col cols="12" lg="12" md="12">
                        <v-row justify="center" style="margin:auto">
                            <v-col hidden>
                                <s-text
                                v-model="FasID"
                                >

                                </s-text>
                            </v-col>
                            <v-col>
                                <s-text label="Temperatura de Ingreso" type="Number" v-model="IncomeTemp"></s-text>
                            </v-col>
                        </v-row>
                        <v-row justify="center" style="margin:auto">
                            <v-col >
                                <s-select 
                                    :items="itemscontenedores"
                                    item-value="FpcID"
                                    item-text="FpcDescription"
                                    label="Seleccionar contenedor"
                                    return-object
                                    autocomplete
                                    v-model="objContainer"
                                    :slotTmp="true">
                                    <template v-slot:item="data">
                                        {{ data.item.FpcDescription }}
                                        <v-chip 
                                            small 
                                            class="ma-2"
                                            variant="outlined"
                                            :color="data.item.inColor"
											><b>{{
												data.item.inCarUsage
											}}</b>

                                        </v-chip>
                                    </template>
                                </s-select>
                            </v-col>
                        </v-row>
                           
                            
                    </v-col>
                </v-row>
                
                <v-row>
                    <v-col cols="12" md="4" lg="3" class="mt-2 pb-0">
                        <v-btn
                            small
                            elevation="0"                            
                            dark
                            color="success"
                            @click="save()"
                            
                        >
                            Cambiar Tunel
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
					<v-col class="pb-5">
						<v-divider></v-divider>                       
					</v-col>
				</v-row>
            </v-container>
        </v-card>
    </div>
</template>

<script>
    

    import _sProcessContainers from '@/services/FrozenProduction/FrzCtnProcessContainers.js';

    export default{
        props:{
            FpcID:"",
            DescriptionTunnel:"",
            selected: {
                required: false,
                default: Array,
            },  
            FasID:{
                required:true,
                type:Number,
            },
            PccID:{
                required:true,
                type:Number,
            },
           
        }, 
        components: {},
        data() {
            return{
                carrito: this.selected,
                itemscontenedores: [],
                objContainer: {},
                IncomeTemp: 0,
            }
        },

        created(){
            this.initialize();
        },

        methods:{
            initialize()
            {             
                _sProcessContainers.list({FpcArea: 2}, this.$fun.getUserID()).then(r => {
                    if(r.status == 200)
                    {
                        r.data.forEach(element => {
                            element.ParihuelaUsagePorcentaje = (100 * element.inCarUsage) /  parseInt(element.FpcCapacity)
                            element.ConatinerAvailableUnit = parseInt(element.FpcCapacity) - parseInt(element.inCarUsage);

                            if(element.ParihuelaUsagePorcentaje >= 100){
								element.inColor = 'error'
							}

							//Tunnel por llenarse
							if(element.ParihuelaUsagePorcentaje >= 80 && element.ParihuelaUsagePorcentaje < 100){
								element.inColor = 'warning'
							}

							if(element.ParihuelaUsagePorcentaje > 35 && element.ParihuelaUsagePorcentaje <= 79){
								element.inColor = 'info'
							}

							if(element.ParihuelaUsagePorcentaje <= 35){
								element.inColor = 'success'
							}
                        });
                        this.itemscontenedores = r.data;             
                              
                    }
                });
            },
                   
             save(){
                // console.log('cambiar carrito de tunel');
                let item = {}
                if(this.objContainer.ConatinerAvailableUnit == 0){
                    this.$fun.alert("Error, contenedor se encuentra lleno. Por favor verifique", "warning")
                    return;
                }
                console.log(this.objContainer);
                if(this.objContainer.FpcID== undefined)
                {
                    this.$fun.alert("Error, seleccione un contenedor", "warning")
                    return;
                }

                item.FpcID = this.objContainer.FpcID;
                item.FasID = this.FasID;
                item.PccID = this.PccID;
                item.IncomeTemp = this.IncomeTemp;
                item.UsrCreateID = this.$fun.getUserID();
                item.UsrUpdateID = this.$fun.getUserID();

                
                // return
                _sProcessContainers.transfer(item, this.$fun.getUserID())
                .then(resp => {
                    if(resp.status = 200)
                    {
                        this.$fun.alert("Registrado correctamente", "success");
                        this.$emit('close')
                    }
                });

             }

            
           
        },

        
        
    };
</script>