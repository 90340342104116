<template>
    <div>
        <v-card>
        <s-toolbar dense dark color="#BDBDBD" close @close="close()">
            {{ itemdataContainer.FpcDescription }}
        </s-toolbar>
            <v-card dense>
                <v-container>
                    <v-row justify="center"  md="12" lg="12">
                        <v-col cols="12" md="12" lg="12" class="mt-3 pb-4">
                            <v-data-table
                                :items="dataContainer"
                                :headers="headers"                
                                item-key="PccID"
                               
                                dense
                                disable-sort
                            >
                            <template v-slot:item.ChangeEnvironment="{ item }">
                                <v-btn 
                                    x-small
                                    elevation="0"
                                    fab
                                    dark
                                    color="primary"
                                    @click="openModalChangeStateTunel(item)" >
                                    <i class="fas fa-exchange-alt"></i>
                                </v-btn>
                            </template>
                            </v-data-table>
                            <!-- v-model="selectedItemsSend" -->
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>

            <v-dialog 
                persistent 
                v-model="modalChangeTunnel" 
                v-if="modalChangeTunnel"
                width="1200"
            >
               <frz-ctn-change-enviroment
                :FpcID ="FpcID"
                :DescriptionTunnel = "itemdataContainer.FpcDescription"
                @close="closeDetail"
                :FasID ="FasID"
                :PccID ="PccID"
               >

               </frz-ctn-change-enviroment>
            </v-dialog>

        </v-card>    
    </div>
</template>

<script>
    import _sProcessContainers from '@/services/FrozenProduction/FrzCtnProcessContainers.js';
    import FrzCtnChangeEnviroment from './FrzCtnChangeEnviroment.vue';
    
    export default{
        props:{
            FpcID: null,
            itemdataContainer: null,
        },
        components: {
          FrzCtnChangeEnviroment
        },
        data(){
            return {
                dataContainer:[],
                headers:[
                    { text: "ID Estático", value: "FasID", width:"70" },
                    { text: "Cliente", value: "CumNombre", align: "center", width: "10%", sortable: true },
                    { text: "Marca", value: "CbdBrans", align: "center", width: "10%", sortable: true },
                    { text: "Cultivo", value: "TypeCultiveName", align: "center", width: "10%", sortable: true },
                    { text: "Variedad", value: "VrtDescription", align: "center", width: "10%", sortable: true },
                    { text: "Corte", value: "TypeCutName", align: "center", width: "10%", sortable: true },
                    { text: "Tipo de Carro", value: "TypeCarConditionedName", align: "center", width: "10%", sortable: true },
                    { text: "Numero de mallas", value: "NumberBags", align: "center", width: "10%", sortable: true },
                    { text: "Peso prom. malla", value: "WeightAverageBags", align: "center", width: "10%", sortable: true },
                    { text: "Temperatura I", value: "IncomeTemp", align: "center", width: "10%", sortable: true },
                    { text: "Fecha de ingreso", value: "PccDateBegin", align: "center", width: "10%", sortable: true },
                    { text: "Hora de ingreso", value: "HourDateBegin", align: "center", width: "10%", sortable: true },
                    { text: "Cambiar Ambiente", value: "ChangeEnvironment", align: "center", width: "10%", sortable: true },
                   
                ],
                modalChangeTunnel:false,
            }
        },
        methods:{
            close(){
                this.$emit("close");
            },
            closeDetail(){
                this.modalChangeTunnel = false
                this.initialize();
            },
            initialize(){
                
                
                _sProcessContainers.getDetail(this.FpcID, this.$fun.getUserID())
                .then(resp => {
                    if(resp.status == 200) {
                        this.dataContainer = resp.data;
                    }
                });
            },
            openModalChangeStateTunel(item){
                console.log('Cambiar de tunel');
                this.modalChangeTunnel = true;
                this.itemChange = item;
                this.FasID = item.FasID;
                this.PccID = item.PccID;
                console.log('Select mover ->',item);
            }
        },
        created(){
            this.initialize();
        }
    }
</script>